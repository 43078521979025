import React, {useEffect, useState} from 'react'
import Truncate from "react-text-truncate";


const ComponentBlog = () => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch("https://admin-kotimo.herokuapp.com/api/posts?sort=createdAt:desc&populate=*")
            .then((res) => res.json())
            .then((data) => setItems(data));
    }, []);


    return (
        <div className=" container-autres-articles">
            <h2 className="title-blog">Blog</h2>
            <div>
                <div className={items.data?.length > 0 ? "container-aa-cards" : "container-aa-cards-empty"}>
                    {items.data?.length > 0 ? (
                        items.data.slice(0, 3).map(item => (
                            <a key={item.attributes.slug} href={`/blog/${item.attributes.slug}`}>
                                <div className="container-card">
                                    <div className="container-img-component">
                                        <img alt="Cover de l'article" src={item.attributes.photo.data?.attributes.url}/>
                                    </div>
                                    <div className="container-txt-component">
                                        <p className="category-blog">{item.attributes.category.data?.attributes.name}</p>
                                        <h2>{item.attributes.titre}</h2>
                                        <p className="duree-lecture">Durée de
                                            lecture: {item.attributes.duree_de_lecture} min</p>
                                        <Truncate
                                            line={3}
                                            element="p"
                                            truncateText="..."
                                            text={item.attributes.Extrait_d_article}
                                        />
                                        <a className="button-v" href={`/blog/${item.attributes.slug}`}>Lire
                                            l'article</a>
                                    </div>
                                </div>
                            </a>
                        ))
                    ) : (
                        <p>Pas d'articles disponibles</p>
                    )}


                </div>

            </div>
            <div className="flex justify-center">
                <a className="button-v" href="/blog">Tous les articles</a>
            </div>
        </div>
    );
}

export default ComponentBlog;