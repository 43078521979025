import { useState, useEffect} from "react";
import * as React from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {Breadcrumbs} from "@mui/material";
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import GoogleMapReact from "google-map-react";
import FormContact from "../components/FormContact/FooterFormContact";
import {Calculator} from "../components/Home/Calculator";
import Header from "../components/Header/Header";
import parse from 'html-react-parser';
import {FormattedNumber, IntlProvider} from "react-intl";


const AnyReactComponent = ({  img }) => <img alt="icone-bien" src={img}/>;

const ProgrammeSingle = () => {
    let {idprogramme} = useParams();

    const [items, setItems] = useState([]);
    useEffect(() => {
        fetch(`https://api.leizee.com/programme?idprogramme=${idprogramme}`, {headers: {
                'Authorization': 'Basic ' + btoa('kotimo-agence-dewey:i00a1n41wrf3zxn1b347y5d87gz5f9')
            }})
            .then((res) => res.json())
            .then((data) => setItems(data));
    }, []);

    const [expanded, setExpanded] = React.useState(false);

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

    const slugify = str =>
        str
            .toLowerCase()
            .trim()
            .replace(/ /m, "_")
            .replace(/ "é" /gm, "e");


    if (items?.length < 1) return  <div className="loader-ville"><p>Chargement ...</p></div>



    return(
        <div key={idprogramme}>
            <Helmet>
                <title>KOTIMO - Programme</title>
            </Helmet>

            <Header/>


            <div className="container container-page-single-prog">
                <div className="header-single-prog">
                    <div className="txt-header-single-prog">
                        <Breadcrumbs className="fil-ariane" separator=">" aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                Accueil
                            </Link>
                            <Link underline="hover" color="inherit" href="/programmes">
                                Programmes
                                </Link>
                            {/*
                                    <Link underline="hover" color="inherit" href="/programmes">
                                {items?.resultat[0].region}
                                    </Link>
                                    <Link underline="hover" color="inherit" href="/programmes">
                                {items?.resultat[0].departement}
                                    </Link>*/}
                            <Link underline="hover" color="inherit" href={`/programmes/` + slugify(`${items?.resultat[0].ville}`)}>
                                {items?.resultat[0].ville}
                            </Link>
                            <Link underline="always" color="inherit">
                                {items?.resultat[0].nom}
                            </Link>
                        </Breadcrumbs>
                        <h1>{items?.resultat[0].nom}</h1>
                        <p>Localisation du
                            programme: {items?.resultat[0].ville},<span>{items?.resultat[0].pays}</span>
                        </p>
                    </div>


                    <div className="container-images-single-prog">
                        <img alt="Batiment" src={items?.resultat[0].url_vue_principale}/>
                        <a target="_blank" href="https://calendly.com/kotimo/30min?back=1&month=2022-12" className="mt-7 button-v">Contactez-nous</a>
                    </div>
                    <div className=" btns-contact">
                        <a className="button-v mr-2" href="tel:+33629365682">Nous appeler</a>
                        <a className="button-v mt-2" href="mailto:commercial@kotimo.fr">Nous envoyer un mail</a>
                    </div>
                </div>


                <div className="container-cara-ptsforts-single-prog">
                    <div className="container-cara">
                        <h2>Présentation</h2>
                        <p>{parse(`${items?.resultat[0].descriptif_format_html}`)}</p>
                    </div>

                    <div className="container-ptsforts">
                        <h2>L'AVIS D'ADELINE</h2>
                        <p>{parse(`${items?.resultat[0].redactionnel_points_forts_format_html}`)}</p>
                    </div>
                </div>


                <div className="container-biens-dispo">
                    <h2>Biens disponibles</h2>
                    <div className="my-5
                    container-tableau">

                        <div>
                            <TableContainer component={Paper} className="container-tableau">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead className="header-tableau">
                                        <TableRow>
                                            <TableCell>Numéro de lot</TableCell>
                                            <TableCell align="right">Genre</TableCell>
                                            <TableCell align="right">Type</TableCell>
                                            <TableCell align="right">Surface</TableCell>
                                            <TableCell align="right">Prix</TableCell>
                                            <TableCell align="right">Disponiblilité</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.resultat[0]?.tranches[0]?.lots.map((row) => (
                                            <TableRow
                                                key={row.idlot}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.idlot}
                                                </TableCell>
                                                <TableCell align="right">{row?.genre}</TableCell>
                                                <TableCell align="right">{row?.type || 'Pas de type'}</TableCell>
                                                <TableCell align="right">{row?.surface_totale} m2</TableCell>
                                                <TableCell align="right"> <IntlProvider locale='fr'>
                                                    <FormattedNumber value={row?.prix[0]?.valeur <50000 ? '' : row?.prix[0]?.valeur || 'Prix manquant'}/></IntlProvider>€</TableCell>
                                                <TableCell align="right">{row?.etat}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            {/*{
                                items.resultat[0].tranches[0].lots.map(tranche => (
                                ))
                            }*/}
                        </div>

                    </div>
                    <a href="/video" className="button-v">Mon projet</a>
                </div>

                <div className="container-googlemaps">
                    <h2>Localisation du programme</h2>
                    <div style={{height: '500px', width: '100%'}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: 'AIzaSyC3rPKILqUjgh2T5DoLYwLbCwNyrUNOOqk'}}
                            defaultZoom={16}
                            center={{
                                lat: items?.resultat[0].latitude,
                                lng: items?.resultat[0].longitude}}
                        >
                                <AnyReactComponent
                                    lat={items?.resultat[0].latitude}
                                    lng={items?.resultat[0].longitude}
                                    img={require('../images/icone-bien.png')}
                                />
                        </GoogleMapReact>
                    </div>

                </div>

                <Calculator/>


                <div className="container-calcu mt-14">
                    <h2 className="mb-7 text-xl text-center uppercase">Contactez-nous</h2>
                    <FormContact/>
                </div>

            </div>
        </div>
    );
}
export default ProgrammeSingle;