import { useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import * as dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import Helmet from "react-helmet";
import {Breadcrumbs} from "@mui/material";
import ReactMarkdown from "react-markdown";


import AutresArticles from "../components/AutresArticles/AutresArticles";
import SocialsList from "../components/SocialsList/SocialsList";
import Header from "../components/Header/Header";

const PageSingleBlog = () => {

    let { slug } = useParams();

    const[items, setItems] = useState();
    const control = useAnimation()
    const [ref, inView] = useInView()
    const display = {
        hidden: {opacity: 0},
        visible: {opacity: 1}
    }

    useEffect(() => {
        dayjs.extend(relativeTime);
        let updateLocale = require('dayjs/plugin/updateLocale')
        dayjs.extend(updateLocale)
        dayjs.updateLocale('en', {
            months: [
                "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet",
                "Août", "Septembre", "Octobre", "Novembre", "Decembre"
            ],
            weekdays: [
                "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"
            ]

        })
        if (inView) {

            control.start("visible");
        }
        else{
            control.start("hidden");
        }
        fetch(`https://admin-kotimo.herokuapp.com/api/posts?filters[slug][$eq]=${slug}&populate=*`)
            .then((res) => res.json())
            .then((data) => setItems(data))
    }, [control, inView]);



    return(
        <div key={items?.data[0].id}>
            <Helmet>
                <title>KOTIMO - </title>
            </Helmet>
            <Header/>

            <div className="container-single-blog px-5 md:px-24">

                <div className="header-blog-single">
                    <Breadcrumbs className=" text-black dark:text-white fil-ariane" separator=">" aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/">
                            Kotimo
                        </Link>
                        <Link underline="hover" color="inherit" to="/blog">
                            Blog
                        </Link>
                        <Link underline="hover" color="inherit"
                              to={"https://kotimo.fr/blog/" + items?.data[0].attributes.category?.data?.attributes.slug}>
                            {items?.data[0].attributes.category?.data?.attributes.name}
                        </Link>
                        <Link underline="always" color="inherit" to={"https://kotimo.fr/blog/" + items?.data[0].attributes.titre}>
                            {items?.data[0].attributes.titre}
                        </Link>
                    </Breadcrumbs>
                    <p className="category-blog">{items?.data[0].attributes.category?.data?.attributes.name}</p>
                    <div className="titleretour"><a href="/blog"><img src={require("../images/icone-retour-blog.png")}/></a>
                        <h1 className="title-single-blog">{items?.data[0].attributes.titre}</h1>
                    </div>
                    <p className="postedAt text-black dark:text-white ">Posté le {dayjs(items?.data[0].attributes.publishedAt).format('dddd')} {dayjs(items?.data[0].attributes.publishedAt).format('DD')} {dayjs(items?.data[0].attributes.publishedAt).format('MMMM')} {dayjs(items?.data[0].attributes.publishedAt).format('YYYY')} a {dayjs(items?.data[0].attributes.publishedAt).format('HH')}h{dayjs(items?.data[0].attributes.publishedAt).format('ss')}</p>
                    <p>Durée de lecture: {items?.data[0].attributes.duree_de_lecture} min</p>
                    <SocialsList/>

                </div>

                <div className=" container-bloc-1-single">

                    <div className="container-image-1-single">
                        <img src={items?.data[0].attributes.photo.data?.attributes.url}/>
                    </div>



                </div>

                <motion.div
                    initial="hidden"
                    ref={ref}
                    variants={display}
                    animate={control}
                    className="container-paragraphe-1">
                    <ReactMarkdown skipHTML={true}>
                        {items?.data[0].attributes.paragraphe_1}
                    </ReactMarkdown>
                </motion.div>
            </div>
            <div className="container-v2">
                <AutresArticles/>
            </div>
        </div>
    )
}

export default PageSingleBlog